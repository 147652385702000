<script>
  import filtersInfo from "../stores/filtersInfo";
  import filterValues from "../stores/filterValues";
  import formatNumber from "../utils/formatNumber";
  import nextKmsStep from "../utils/nextKmsStep";
  import MenuSelect from "../ui/MenuSelect.svelte";
  import SelectAutocomplete from "../ui/SelectAutocomplete.svelte";

  export let compact = false;
  // borders
  export let borderLeft = false;
  export let borderTop = false;
  export let borderBottom = false;
  export let borderRight = false;

  let options = [];
  $: {
    const { lowestKms, highestKms } = $filtersInfo;
    const items = [];
    let kms = lowestKms;

    while(kms < Math.min(highestKms, $filterValues.highestKms || highestKms)) {
      items.push(kms);
      kms = nextKmsStep(kms);
    }

    options = items.map(kms => ({
      value: kms,
      label: `${formatNumber(kms)} kms`,
    }))
  }

  $: label = $filterValues.lowestKms ?
    `Desde ${$filterValues.lowestKms} kms` : 'Kms desde';

</script>

{#if compact}
  <MenuSelect
    bind:value={$filterValues.lowestKms}
    label={label}
    options={options}
  />
{:else}
  <SelectAutocomplete
    borderLeft={borderLeft}
    borderTop={borderTop}
    borderBottom={borderBottom}
    borderRight={borderRight}
    label="Kms desde"
    noOptionValue="Kms desde"
    options={options}
    bind:value={$filterValues.lowestKms}
    hideSearch
  />
{/if}