<script>
  export let options = [];
  export let value = null;
  export let withSearch = false;

  let searchElement;

  $: if(searchElement) {
    searchElement.focus();
  }

  let search = '';

  function selectOption(option) {
    value = option ? option.value : null;
  }

  $: filteredOptions = !search ? options : options
    .filter(option => option.value.toLowerCase().includes(search.toLowerCase()));
</script>

<div>
  {#if withSearch}
    <div class="p-2">
      <input
        bind:value={search}
        bind:this={searchElement}
        class="border-1 border-black rounded h-8 px-2 w-full"
      />
    </div>
  {/if}
  {#each filteredOptions as option}
    <button
      class="block border-0 w-full bg-transparent h-8 flex items-center p-2 hover:(bg-gray-200)"
      on:click={() => selectOption(option)}
    >
      <span class="px-2 whitespace-nowrap">
        {option.label}
      </span>
      {#if option.value === value}
        <button
          class="border-0 rounded-full self-stretch w-8 -m-2 ml-0 bg-transparent hover:(bg-gray-300)"
          on:click|stopPropagation={() => selectOption(null)}
        >
          ✕
        </button>
      {/if}
    </button>
  {/each}
</div>
