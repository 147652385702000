<script>
  import filtersInfo from '../stores/filtersInfo';
  import { getSearchProps } from '../stores/searchProps';
  import DynamicFilter from './DynamicFilter.svelte';
  import FiltersContainer from './FiltersContainer.svelte';
  import RedirectButton from './RedirectButton.svelte';

  const searchProps = getSearchProps();

  $: linkFilters = $searchProps.linkFilters;
  $: mainFilters = $searchProps.mainFilters;
  $: ready = $filtersInfo.ready;
</script>

<style>
  .blur {
    filter: blur(5px);
    pointer-events: 'none';
  }
</style>

<section 
  class="lg:w-60re flex flex-col"
  class:blur={!ready}
>
  <div class="flex flex-wrap">
    <FiltersContainer filters={mainFilters} />
    <div class="search-button flex flex-col-reverse w-full md:(w-1/4)">
      <RedirectButton />
    </div>
  </div>
  {#if linkFilters.length}
    <div class="flex py-1 -md:(hidden)">
      {#each linkFilters as filterType, index }
        <div class:ml-2={index > 0}>
          <DynamicFilter
            compact
            type={filterType}
          />
        </div>
      {/each}
    </div>
  {/if}
</section>
