<script>
  export let gross = '16px';  
  export let size = '120px';
  export let speed = '1s';
</script>

<style>
  div {
    border: var(--gross) solid #f3f3f3;
    border-top: var(--gross) solid var(--primary);
    border-radius: 50%;
    width: var(--size);
    height: var(--size);
    animation: spin var(--speed) linear infinite;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
</style>

<div
  style="--gross: {gross}; --size: {size}; --speed: {speed}"
/>
