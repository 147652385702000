import { writable } from "svelte/store";

const filtersInfo = writable({
  brands: null,
  highestPrice: 0,
  highestKms: 0,
  lowestPrice: 0,
  lowestKms: 0,
  models: null,
  states: null,
  transmissions: null,
  total: null,
  ready: false,
});

export default filtersInfo;
