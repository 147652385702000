import camelizeAttribute from "./camelizeAttribute";

export default function defineCustomComponent({
  component,
  tagName,
  props,
}) {
  class CustomComponent extends HTMLElement {
    static get observedAttributes() {
      return Object.keys(props);
    }
    
    attributeChangedCallback(name, oldValue, newValue) {
      if (!this.component) return;

      const propName = camelizeAttribute(name);
      const propValue = props[name](newValue);

      this.component.$$set({ [propName]: propValue });
    }

    connectedCallback() {
      this.component = new component({
        target: this,
        props: this.getAttributes(),
      })
    }
  
    getAttributes() {
      const attributes = Object.entries(props).reduce((accumulator, [attributeName, attributeParser]) => {
        const attribute = this.attributes.getNamedItem(attributeName);

        const propName = camelizeAttribute(attributeName);
        const propValue = attributeParser(attribute ? attribute.value : undefined);

        return {
          ...accumulator,
          [propName]: propValue,
        };
      }, {});

      return attributes;
    }
  }

  customElements.define(tagName, CustomComponent);

  return CustomComponent;
}
