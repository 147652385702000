<script>
  import filtersInfo from "../stores/filtersInfo";
  import filterValues from "../stores/filterValues";
  import formatNumber from "../utils/formatNumber";
  import nextPriceStep from "../utils/nextPriceStep";
  import MenuSelect from "../ui/MenuSelect.svelte";
  import SelectAutocomplete from "../ui/SelectAutocomplete.svelte";

  export let compact = false;
  // borders
  export let borderLeft = false;
  export let borderTop = false;
  export let borderBottom = false;
  export let borderRight = false;

  let options = [];
  $: {
    const prices = [];
    const { lowestPrice, highestPrice } = $filtersInfo;

    let price = Math.max(lowestPrice, $filterValues.lowestPrice || lowestPrice);

    while(price < highestPrice) {
      price = nextPriceStep(price);
      prices.push(price);
    }

    options = prices.map(price => ({
      value: price,
      label: `$${formatNumber(price)}`,
    }))
  }

  $: label = $filterValues.highestPrice ?
    `Hasta $${$filterValues.highestPrice}` : 'Precio hasta';

</script>

{#if compact}
  <MenuSelect
    bind:value={$filterValues.highestPrice}
    label={label}
    options={options}
  />
{:else}
  <SelectAutocomplete
    borderLeft={borderLeft}
    borderTop={borderTop}
    borderBottom={borderBottom}
    borderRight={borderRight}
    label="Precio hasta"
    noOptionValue="Precio hasta"
    options={options}
    bind:value={$filterValues.highestPrice}
    hideSearch
  />
{/if}
