export default function cellBorders(index, length) {
  const colOf = (col) => col % length;
  const isFirstRow = (index) => index < length;

  return {
    l: colOf(index) == 0,
    t: isFirstRow(index),
    r: true,
    b: true,
  };
}
