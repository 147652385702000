const searchVehiclesQuery = `
  query(
    $bodyTypes: [String!]
    $brands: [String!]
    $highestPrice: Int
    $highestKms: Int
    $lowestPrice: Int
    $lowestKms: Int
    $models: [String!]
    $search: String
    $states: [String!]
    $transmissions: [String!]
    $page: Int
  ) {
    searchVehicles(input: {
      bodyTypes: $bodyTypes
      brands: $brands
      maxKms: $highestKms
      maxPrice: $highestPrice
      minKms: $lowestKms
      minPrice: $lowestPrice
      models: $models
      page: $page
      search: $search
      states: $states
      transmissions: $transmissions
    }) {
      pages
      items {
        description
        id
        kms
        location
        picture
        price
        year
      }
    }
  }
`;

export default searchVehiclesQuery;
