<script>
  import TextInput from "./TextInput.svelte";

  export let value = '';
  export let label = '';
  // borders
  export let borderLeft = false;
  export let borderTop = false;
  export let borderBottom = false;
  export let borderRight = false;

  let prevValue = value;
  let currentValue = '';

  $: if (prevValue !== value) {
    prevValue = value;
    currentValue = value;
  }

  function change() {
    value = currentValue;
  }
</script>

<TextInput
  label={label}
  borderLeft={borderLeft}
  borderTop={borderTop}
  borderBottom={borderBottom}
  borderRight={borderRight}
  bind:value={currentValue}
  on:change={change}
/>
