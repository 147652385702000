<script>
  import gqlFetch from '../utils/gqlFetch';
  import getFiltersInfoQuery from '../queries/getFiltersInfoQuery';
  import filterValues from '../stores/filterValues';
  import { createSearchProps } from '../stores/searchProps';
  import createChecker from '../utils/createChecker';
  import filtersInfo from '../stores/filtersInfo';
  import FormVariant from './FormVariant.svelte';
  import SidebarVariant from './SidebarVariant.svelte';

  // attributes
  export let appUrl = 'http://localhost:4000';
  export let apiUrl = 'http://localhost:4001';
  export let mainFilters = [];
  export let linkFilters = [];
  export let noRedirect = false;
  export let sidebar = false;
  export let fullWidth = false;
  
  // save attributes into a store
  const searchProps = createSearchProps();

  $: $searchProps.appUrl = appUrl;
  $: $searchProps.apiUrl = apiUrl;
  $: $searchProps.mainFilters = mainFilters;
  $: $searchProps.linkFilters = linkFilters;
  $: $searchProps.noRedirect = noRedirect;
  $: $searchProps.sidebar = sidebar;
  $: $searchProps.fullWidth = fullWidth;

  // refetch filters info
  $: gqlFetch({
    query: getFiltersInfoQuery,
    url: apiUrl,
    variables: $filterValues,
  }).then(({ data }) => {
    $filtersInfo = {
      ...data.getFiltersInfo,
      ready: true,
    };
  });

  const changedEventChecker = createChecker(() => {
    const event = new CustomEvent('filtersChanged', {
      detail: $filterValues,
    });

    window.dispatchEvent(event); 
  }, ...Object.values($filterValues));

  $: changedEventChecker(...Object.values($filterValues));
</script>

{#if sidebar}
  <SidebarVariant />
{:else}
  <FormVariant />
{/if}
