<script>
  import ArrowIcon from "../ui/ArrowIcon.svelte";

  export let value = 0;
  export let pages = 10;

  let from;
  let to;

  $: {
    if (value - 2 < 0) {
      from = 0;
      to = Math.min(5, pages);
    } else if (value + 2 >= pages) {
      from = Math.max(0, pages - 5);
      to = pages;
    } else {
      from = value - 2;
      to = value + 3;
    }
  };

  $: hasPrev = value > 0;
  $: hasNext = value < pages - 1;

  function prev() {
    if (hasPrev) value--;
  }
  
  function next() {
    if (hasNext) value++;
  }

  $: items = new Array(to - from)
    .fill()
    .map((item, index) => index + from);
</script>

<div class="flex items-center">
  <button
    class="w-8 h-8 bg-transparent rounded-full"
    class:text-black={hasPrev}
    class:text-gray-400={!hasPrev}
    on:click={prev}
  >
    <ArrowIcon left />
  </button>

  {#each items as item}
    <button
      on:click={() => value = item}
      class="m-1 rounded-full w-8 h-8"
      class:bg-gray-200={value === item}
      class:bg-transparent={value !== item}
    >
      {item + 1}
    </button>
  {/each}

  <button
    class="w-8 h-8 bg-transparent rounded-full"
    class:text-black={hasNext}
    class:text-gray-400={!hasNext}
    on:click={next}
  >
    <ArrowIcon right />
  </button>
</div>
