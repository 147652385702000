import stepIncrement from "./stepIncrement";

export default function nextPriceStep(price) {
  if (price < 10000) {
    return 10000;
  }

  return stepIncrement({ max: 100000, step: 10000, current: price }) ||
    stepIncrement({ max: 500000, step: 50000, current: price }) ||
    stepIncrement({ max: 1000000, step: 100000, current: price }) ||
    stepIncrement({ max: Infinity, step: 500000, current: price})
  ;
}
