<script>
  import ArrowIcon from "./ArrowIcon.svelte";
  import FloatingMenu from "./FloatingMenu.svelte";
  import OptionsList from "./OptionsList.svelte";

  export let label = '';
  export let value = null;
  export let options = [];

  let triggerElement = null;
  let visible = true;

  $: value, visible = false;

  function toggleMenu() {
    visible = !visible;
  }

  // This does not work, the label must be set by parent component (desde... hasta...)

  // $: selectedOption = options.find(option => option.value === value);

  // $: displayLabel =
  //   selectedOption ? selectedOption.label : // show label of selected option
  //   value ? noOptionValue : // no selected option but has value, show alternative label
  //   label // it has neither selected option nor value, show the label
  // ;
</script>


<div bind:this={triggerElement} class="relative">
  <span class="flex items-center cursor-pointer border-b-1 border-black" on:click={toggleMenu}>
    {label}
    <ArrowIcon marginLeft />
  </span>
  <FloatingMenu bind:visible={visible} triggerElement={triggerElement}>
    <OptionsList
      options={options}
      bind:value={value}
    />
  </FloatingMenu>
</div>