<script>
  import filtersInfo from "../stores/filtersInfo";
  import filterValues from "../stores/filterValues";
  import nextPriceStep from "../utils/nextPriceStep";
  import formatNumber from '../utils/formatNumber';
  import MenuSelect from "../ui/MenuSelect.svelte";
  import SelectAutocomplete from "../ui/SelectAutocomplete.svelte";

  export let compact = false;
  // borders
  export let borderLeft = false;
  export let borderTop = false;
  export let borderBottom = false;
  export let borderRight = false;

  let options = [];
  $: {
    const prices = [];
    const { lowestPrice, highestPrice } = $filtersInfo;
    let price = lowestPrice;

    while(price < Math.min(highestPrice, $filterValues.highestPrice || highestPrice)) {
      prices.push(price);
      price = nextPriceStep(price);
    }

    options = prices.map(price => ({
      value: price,
      label: `$${formatNumber(price)}`,
    }))
  }

  $: label = $filterValues.lowestPrice ?
    `Desde $${$filterValues.lowestPrice}` : 'Precio desde';

</script>

{#if compact}
  <MenuSelect
    bind:value={$filterValues.lowestPrice}
    label={label}
    options={options}
  />
{:else}
  <SelectAutocomplete
    borderLeft={borderLeft}
    borderTop={borderTop}
    borderBottom={borderBottom}
    borderRight={borderRight}
    label="Precio desde"
    noOptionValue="Precio desde"
    options={options}
    bind:value={$filterValues.lowestPrice}
    hideSearch
  />
{/if}
