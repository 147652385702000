<script>
  export let marginLeft = false;
  export let left = false;
  export let right = false;
</script>

<style>
  .left {
    transform: rotate(90deg);
  }
  .right {
    transform: rotate(-90deg);
  }
</style>

<svg
  class="h-4 fill-current"
  class:!ml-1={marginLeft}
  class:left
  class:right
  version="1.1"
  viewBox="0 0 129 129"
  enable-background="new 0 0 129 129"
>
  <g>
    <path d="m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z"/>
  </g>
</svg>
