<script>
  import filterValues from '../stores/filterValues';
  import filtersInfo from '../stores/filtersInfo';
  import SelectAutocomplete from '../ui/SelectAutocomplete.svelte';
  import MenuSelect from '../ui/MenuSelect.svelte';

  export let compact = false;
  // borders
  export let borderLeft = false;
  export let borderTop = false;
  export let borderBottom = false;
  export let borderRight = false;

  let prevBrand = $filterValues.brand;

  // reset model when brand is changed
  $: {
    if ($filterValues.brand !== prevBrand) {
      prevBrand = $filterValues.brand;
      $filterValues.model = null;
    }
  }

  $: options = ($filtersInfo.brands || []).map((model) => ({
    label: `${model.name} (${model.count})`,
    value: model.name,
  }));

  $: selectedOption = options
    .find((option) => option.value === $filterValues.brand);

  $: label =
    selectedOption ? selectedOption.label :
    $filterValues.brand ? `${$filterValues.brand}(0)` :
    'Marca';
</script>

{#if compact}
  <MenuSelect
    label={label}
    options={options}
    bind:value={$filterValues.brand}
  />
{:else}
  <SelectAutocomplete
    borderLeft={borderLeft}
    borderTop={borderTop}
    borderBottom={borderBottom}
    borderRight={borderRight}
    label="Marca"
    noOptionValue="{$filterValues.brand} (0)"
    options={options}
    bind:value={$filterValues.brand}
  />
{/if}
