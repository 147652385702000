import { writable } from "svelte/store";

export const defaultValues = {
  brand: null,
  highestPrice: null,
  highestKms: null,
  lowestPrice: null,
  lowestKms: null,
  model: null,
  search: '',
  state: null,
  transmission: null,
};

const filterValues = {
  ...writable({ ...defaultValues }),
  resetWith: (values) => {
    filterValues.set({
      ...defaultValues,
      ...values,
    });
  },
};

export default filterValues;
