<script>
  import { scrollToNextChild, scrollToPrevChild } from '../utils/horiztonalScroll';
  import VehicleItem from "./VehicleItem.svelte";
  import SideButton from "./SideButton.svelte";
  import SpinLoader from "./SpinLoader.svelte";

  export let appUrl = '';
  export let loading = false;
  export let variant = '';
  export let vehicles = [];

  let sliderElement = null;

  function next() {
    scrollToNextChild(sliderElement);
  }

  function prev() {
    scrollToPrevChild(sliderElement);
  }
</script>


<style>
  nav {
    scroll-behavior: smooth;
  }

  .blur {
    filter: blur(5px);
  }
</style>

<section
  class="relative"
  class:h-17rem={variant === 'compact'}
  class:h-25rem={variant === 'normal'}
>
  <div class:blur={loading} class="h-full">
    <SideButton left on:click={prev} />
      <nav
        class="overflow-x-auto flex p-1"
        bind:this={sliderElement}
      >
        {#each vehicles as vehicle, index}
          <div class:pl-2={index > 0}>
            <VehicleItem
              description={vehicle.description}
              id={vehicle.id}
              kms={vehicle.kms}
              location={vehicle.location}
              picture={vehicle.picture}
              price={vehicle.price}
              year={vehicle.year}
              appUrl={appUrl}
              variant={variant}
            />
          </div>
        {/each}
      </nav>
    <SideButton right on:click={next} />
  </div>
  {#if loading}
    <div class="absolute inset-0 flex items-center justify-center">
      <SpinLoader size="5rem" gross="0.5rem" speed="0.5s" />
    </div>
  {/if}
</section>
