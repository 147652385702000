<script>
  import VehicleItem from "./VehicleItem.svelte";
  import SpinLoader from "./SpinLoader.svelte";

  export let appUrl = '';
  export let loading = false;
  export let variant = '';
  export let vehicles = [];

  $: isCompact = variant === 'compact';
</script>


<style>
  .blur {
    filter: blur(5px);
  }
</style>

<section class="min-h-32 relative">
  <div
    class="
      grid
      grid-cols-1
      sm:grid-cols-2
      lg:grid-cols-3
      xl:grid-cols-4
    "
    class:gap-2={isCompact}
    class:gap-6={!isCompact}
    class:blur={loading}
  >
    {#each vehicles as vehicle}
      <VehicleItem
        autoWidth
        description={vehicle.description}
        id={vehicle.id}
        kms={vehicle.kms}
        location={vehicle.location}
        picture={vehicle.picture}
        price={vehicle.price}
        year={vehicle.year}
        appUrl={appUrl}
        variant={variant}
      />
    {/each}
  </div>
  {#if loading}
    <div class="absolute left-1/2 top-0 transform -translate-x-1/2">
      <SpinLoader size="5rem" gross="0.5rem" speed="0.5s" />
    </div>
  {/if}
</section>
