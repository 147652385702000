<script>
  import filterValues from '../stores/filterValues';
  import filtersInfo from '../stores/filtersInfo';
  import SelectAutocomplete from '../ui/SelectAutocomplete.svelte';
  import MenuSelect from '../ui/MenuSelect.svelte';

  export let compact = false;
  // borders
  export let borderLeft = false;
  export let borderTop = false;
  export let borderBottom = false;
  export let borderRight = false;

  $: options = ($filtersInfo.models || []).map((model) => ({
    label: `${model.name} (${model.count})`,
    value: model.name,
  }));

  $: selectedOption = options
    .find((option) => option.value === $filterValues.model);

  $: label =
    selectedOption ? selectedOption.label :
    $filterValues.model ? `${$filterValues.model}(0)` :
    'Modelo';
</script>

{#if compact}
  <MenuSelect
    bind:value={$filterValues.model}
    label={label}
    options={options}
  />
{:else}
  <SelectAutocomplete
    borderLeft={borderLeft}
    borderTop={borderTop}
    borderBottom={borderBottom}
    borderRight={borderRight}
    label="Modelo"
    noOptionValue="{$filterValues.model} (0)"
    options={options}
    bind:value={$filterValues.model}
  />
{/if}
