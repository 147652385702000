<script>
  import filtersInfo from "../stores/filtersInfo";
  import { getSearchProps } from '../stores/searchProps';
  import Card from '../ui/Card.svelte';
  import DynamicFilter from './DynamicFilter.svelte';

  const searchProps = getSearchProps();

  $: filters = $searchProps.mainFilters;
  $: fullWidth = $searchProps.fullWidth;
  $: ready = $filtersInfo.ready;
</script>

<style>
  .blur {
    filter: blur(5px);
    pointer-events: 'none';
  }
</style>

<section
  class:blur={!ready}
  class:min-w-full={fullWidth}
  class:w-72={!fullWidth}
>
  <Card flat>
    <div
      class="
        flex
        flex-col
        gap-2
        p-3
      "
    >
      {#each filters as filter}
        <DynamicFilter type={filter} />
      {/each}
    </div>
  </Card>
</section>
