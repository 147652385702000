<script>
  import gqlFetch from "../utils/gqlFetch";
  import searchVehiclesQuery from '../queries/searchVehiclesQuery';
  import RowContainer from "./RowContainer.svelte";
  import GridContainer from "./GridContainer.svelte";
  import Pagination from "./Pagination.svelte";

  export let container = '';
  export let appUrl = 'http://localhost:4000';
  export let apiUrl = 'http://localhost:4001';
  export let variant = '';
  // filters
  export let bodyTypes;
  export let brands;
  export let kmsFrom;
  export let kmsTo;
  export let models;
  export let priceFrom;
  export let priceTo;
  export let search;
  export let states;
  export let transmissions;

  $: _variant = variant || 'normal';
  $: _container = container || 'row';

  let ready = false;
  let vehicles = [];
  let pages = 0;
  let currentPage = 0;

  let prevPage = currentPage;

  $: {
    if (prevPage === currentPage) {
      prevPage = currentPage = 0;
    } else {
      prevPage = currentPage;
    }

    ready = false;
    vehicles = [];
    gqlFetch({
      query: searchVehiclesQuery,
      url: apiUrl,
      variables: {
        bodyTypes: bodyTypes,
        brands: brands,
        highestKms: kmsTo,
        highestPrice: priceTo,
        lowestKms: kmsFrom,
        lowestPrice: priceFrom,
        models: models,
        search: search,
        states: states,
        transmissions: transmissions,
        page: currentPage + 1,
      },
    }).then(({ data }) => {
      ready = true;
      vehicles = data.searchVehicles.items;
      pages = data.searchVehicles.pages
    });
  }
</script>

{#if _container === 'row'}
  <RowContainer
    appUrl={appUrl}
    loading={!ready}
    variant={_variant}
    vehicles={vehicles}
  />
{:else}
  <div>
    <GridContainer
      appUrl={appUrl}
      loading={!ready}
      variant={_variant}
      vehicles={vehicles}
    />
    {#if pages > 1}
      <div class="flex justify-center mt-2">
        <Pagination pages={pages} bind:value={currentPage} />
      </div>
    {/if}
  </div>
{/if}
