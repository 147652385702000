import Search from './SearchComponent/Search.svelte';
import Vehicles from './VehiclesComponent/Vehicles.svelte';
import defineCustomComponent from './utils/defineCustomComponent';

const list = (separator = ' ', defaultValue = []) => (raw) => {
  if (typeof raw === 'string') {
    return raw.split(separator).filter(Boolean);
  }

  return defaultValue;
};

const oneOf = (values, defaultValue = null) => (raw) => {
  if (values.includes(raw)) return raw;

  return defaultValue;
};

const text = (raw) => {
  if (typeof raw === 'string') return raw;
  return null;
};

const boolean = (raw) => {
  if (raw === '' || raw === 'true') return true;
  return false;
};

const number = (raw) => {
  if (raw === '' || raw === undefined) return null;
  return Number(raw)
};

defineCustomComponent({
  component: Search,
  tagName: 'inteli-search',
  props: {
    'app-url': text,
    'api-url': text,
    'main-filters': list(' ', ['search', 'brand', 'model']),
    'link-filters': list(' ', ['vehicle-state', 'transmission', 'lowest-price', 'highest-price', 'highes-kms', 'lowest-kms']),
    'no-redirect': boolean,
    'sidebar': boolean,
    'full-width': boolean,
  },
});

defineCustomComponent({
  component: Vehicles,
  tagName: 'inteli-vehicles',
  props: {
    'app-url': text,
    'api-url': text,
    'container': oneOf(['row', 'grid'], 'row'),
    'variant': oneOf(['normal', 'compact'], 'normal'),
    // filters
    'body-types': list('|'),
    'brands': list('|'),
    'models': list('|'),
    'search': text,
    'states': list('|'),
    'transmissions': list('|'),
    'price-from': number,
    'price-to': number,
    'kms-from': number,
    'kms-to': number,
  },
});
