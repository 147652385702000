<script>
  import ArrowIcon from "../ui/ArrowIcon.svelte";

  export let left = false;
  export let right = false;
</script>

<button
  class="
    cursor-pointer
    absolute
    top-1/2
    transform -translate-y-1/2
    rounded-full
    z-1
    w-10 h-10
    bg-black bg-opacity-50
    text-white
    flex items-center justify-center
  "
  class:left-0={left}
  class:right-0={right}
  on:click
>
  <ArrowIcon left={left} right={right} />
</button>
