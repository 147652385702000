<script>
  import FloatingMenu from './FloatingMenu.svelte';
  import OptionsList from './OptionsList.svelte';
  import ArrowIcon from './ArrowIcon.svelte';

  export let options = [];
  export let value = null;
  export let label = '';
  export let noOptionValue = '';
  export let hideSearch = false;
  // borders
  export let borderLeft = false;
  export let borderTop = false;
  export let borderBottom = false;
  export let borderRight = false;

  let triggerElement;
  let menuVisible = false;

  const toggleMenu = () => {
    menuVisible = !menuVisible;
  }

  $: selectedOption = options.find(option => option.value === value);
  $: value, menuVisible = false;

  $: displayValue =
    selectedOption ? selectedOption.label : // show label of selected option
    value ? noOptionValue : // no selected option but has value, show alternative label
    '' // it has neither selected option nor value, show an empty string
  ;
</script>

<div class="relative">
  <button
    bind:this={triggerElement}
    on:click={toggleMenu}
    class="px-2 shadow-inner w-full flex items-center cursor-pointer h-12 border-black"
    class:border-l={borderLeft}
    class:border-t={borderTop}
    class:border-r={borderRight}
    class:border-b={borderBottom}
  >
    <div class="w-full flex flex-col items-start">
      <span
        class:text-xxs={selectedOption || value}
        class:text-gray-600={selectedOption || value}
      >
        {label}
      </span>
      <span>
        {displayValue}
      </span>
    </div>
    <ArrowIcon />
  </button>
  <FloatingMenu
    bind:visible={menuVisible}
    triggerElement={triggerElement}
  >
    <OptionsList
      options={options}
      bind:value={value}
      withSearch={!hideSearch}
    />
  </FloatingMenu>
</div>
