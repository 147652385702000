<script>
  export let value = '';
  export let label = '';
  // borders
  export let borderLeft = false;
  export let borderTop = false;
  export let borderBottom = false;
  export let borderRight = false;

  let focused = false;
</script>

<label
  class="h-12 flex flex-col justify-center px-2 bg-white border-black"
  class:border-l={borderLeft}
  class:border-t={borderTop}
  class:border-r={borderRight}
  class:border-b={borderBottom}
>
  {#if focused || value}
  <span class="text-xxs text-gray-500">
    {label}
  </span>
  {/if}
  <input
    type="text"
    class="w-full border-0 outline-none"
    placeholder={label}
    on:change
    on:focus={() => focused = true}
    on:blur={() => focused = false}
    bind:value={value}
  />
</label>
