<script>
  import BrandFilter from './BrandFilter.svelte';
  import HighestKmsFilter from './HighestKmsFilter.svelte';
  import HighestPriceFilter from './HighestPriceFilter.svelte';
  import LowestPriceFilter from './LowestPriceFilter.svelte';
  import LowestKmsFilter from './LowestKmsFilter.svelte';
  import ModelFilter from './ModelFilter.svelte';
  import SearchFilter from './SearchFilter.svelte';
  import TransmissionFilter from './TransmissionFilter.svelte';
  import VehicleStateFilter from './VehicleStateFilter.svelte';

  export let type = '';
  export let compact = false;
  // borders
  export let borderLeft = false;
  export let borderTop = false;
  export let borderBottom = false;
  export let borderRight = false;

  $: Filter = (() => {
    switch(type) {
      case 'brand':
        return BrandFilter;
      case 'highes-kms':
        return HighestKmsFilter
      case 'highest-price':
        return HighestPriceFilter
      case 'lowest-price':
        return LowestPriceFilter
      case 'lowest-kms':
        return LowestKmsFilter
      case 'model':
        return ModelFilter
      case 'search':
        return SearchFilter
      case 'transmission':
        return TransmissionFilter
      case 'vehicle-state':
        return VehicleStateFilter;
      default:
        return null;
    }
  })();
</script>

{#if Filter}
  <svelte:component
    this={Filter}
    compact={compact}
    borderLeft={borderLeft}
    borderTop={borderTop}
    borderBottom={borderBottom}
    borderRight={borderRight}
  />
{:else}
  Invalid "{type}" filter
{/if}
