import { getContext, setContext } from 'svelte';
import { writable } from 'svelte/store';

/**
 * @typedef { Object } Props
 * @property { string } appUrl
 * @property { string } apiUrl
 * @property { any[] } linkFilters
 * @property { any[] } mainFilters
 * @property { boolean } noRedirect
 * @property { boolean } sidebar
 * @property { boolean } fullWidth
 */

/**
 * @typedef { import('svelte/store').Writable<Props> } PropsStore
 */

/**
 * @returns { PropsStore }
 */
export function createSearchProps() {
  const searchProps = writable({
    appUrl: '',
    apiUrl: '',
    linkFilters: [],
    mainFilters: [],
    noRedirect: false,
    sidebar: false,
    fullWidth: false,
  });

  setContext('props', searchProps);

  return searchProps;
}

/**
 * @returns { PropsStore }
 */
export function getSearchProps() {
  return getContext('props');
}
