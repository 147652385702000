<script>
  import urlify from '../utils/urlify';
  import CompactItem from './CompactItem.svelte';
  import ProminentItem from './ProminentItem.svelte';

  export let autoWidth = false;
  export let description = '';
  export let id = '';
  export let kms = '';
  export let location = '';
  export let picture = '';
  export let price = '';
  export let year = '';
  export let appUrl = '';
  export let variant = '';

  $: href = `${appUrl}/auto/${urlify(description)}/${id}`;
</script>

{#if variant ==='compact'}
  <CompactItem
    autoWidth={autoWidth}
    description={description}
    kms={kms}
    href={href}
    location={location}
    picture={picture}
    price={price}
    year={year}
  />
{:else}
  <ProminentItem
    autoWidth={autoWidth}
    description={description}
    kms={kms}
    href={href}
    location={location}
    picture={picture}
    price={price}
    year={year}
  />
{/if}
