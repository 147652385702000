<script>
  import filterValues from "../stores/filterValues";
  import LazyInput from "../ui/LazyInput.svelte";

  export let compact = false;
  // borders
  export let borderLeft = false;
  export let borderTop = false;
  export let borderBottom = false;
  export let borderRight = false;
</script>

{#if compact}
  Not supported compact variant for search filter
{:else}
  <LazyInput
    borderLeft={borderLeft}
    borderTop={borderTop}
    borderBottom={borderBottom}
    borderRight={borderRight}
    label="Buscar"
    bind:value={$filterValues.search}
  />
{/if}