<script>
  import filtersInfo from "../stores/filtersInfo";
  import filterValues from "../stores/filterValues";
  import MenuSelect from "../ui/MenuSelect.svelte";
  import SelectAutocomplete from "../ui/SelectAutocomplete.svelte";

  export let compact = false;
  // borders
  export let borderLeft = false;
  export let borderTop = false;
  export let borderBottom = false;
  export let borderRight = false;

  $: options = ($filtersInfo.states || []).map((option) => ({
    label: `${option.name} (${option.count})`,
    value: option.name,
  }));

  $: selectedOption = options
    .find((option) => option.value === $filterValues.state);

  $: label =
    selectedOption ? selectedOption.label :
    $filterValues.state ? `${$filterValues.state}(0)` :
    'Estado';
</script>

{#if compact}
  <MenuSelect
    bind:value={$filterValues.state}
    label={label}
    options={options}
  />
{:else}
  <SelectAutocomplete
    borderLeft={borderLeft}
    borderTop={borderTop}
    borderBottom={borderBottom}
    borderRight={borderRight}
    label="Estado"
    noOptionValue="Estado"
    options={options}
    bind:value={$filterValues.state}
    hideSearch
  />
{/if}
