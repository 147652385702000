<script>
  import { getSearchProps } from '../stores/searchProps';
  import filterValues from "../stores/filterValues";
  import filtersInfo from "../stores/filtersInfo";
  import Button from "../ui/Button.svelte";

  const searchProps = getSearchProps();

  $: appUrl = $searchProps.appUrl;
  $: noRedirect = $searchProps.noRedirect;

  function onClick() {
    const event = new CustomEvent('search', {
      detail: $filterValues,
    });

    window.dispatchEvent(event);

    if (noRedirect) return;

    const url = new URL(`${appUrl}/buscar`);
    const params = url.searchParams;
    const {
      brand,
      highestKms,
      highestPrice,
      lowestKms,
      lowestPrice,
      model,
      search,
      state,
      transmission,
    } = $filterValues;

    if (brand) params.set('brands', brand);

    if (model) params.set('models', model);

    if (state) params.set('states', state);

    if (transmission) params.set('transmissions', transmission);

    if (lowestKms || highestKms) params.set('kms', `${lowestKms || 0}-${highestKms || 'Infinity'}`);

    if (lowestPrice || highestPrice) params.set('prices', `${lowestPrice || 0}-${highestPrice || 'Infinity'}`);

    if (search.trim()) url.pathname = `${url.pathname}/${search}`;

    window.open(url, '_blank')
  }
</script>

<Button on:click={onClick}>
  Buscar
  {#if $filtersInfo.total}
    {$filtersInfo.total} vehiculos
  {/if}
</Button>
