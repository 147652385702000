<script>
  import cellBorders from '../utils/cellBorders';
  import mediaQuery from '../utils/mediaQuery';
  import DynamicFilter from './DynamicFilter.svelte';

  export let filters = [];

  const isDesktop = mediaQuery('(min-width: 768px)');

  $: cols = $isDesktop ? 4 : 1;
  $: items = filters.map((filter, index) => ({
    filter,
    ...cellBorders(index, cols),
  }));
</script>

{#each items as { filter, l, t, r, b } }
  <div class="form-field flex flex-col-reverse w-full md:(w-1/4)">
    <DynamicFilter
      type={filter}
      borderLeft={l}
      borderTop={t}
      borderRight={r}
      borderBottom={b}
    />
  </div>
{/each}
