<script>
  import formatNumber from '../utils/formatNumber';

  export let autoWidth = false;
  export let description = '';
  export let href = '';
  export let kms = '';
  export let location = '';
  export let picture = '';
  export let price = '';
  export let year = '';
</script>

<style>
  .content-shadow {
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  }
  .text-shadow {
    text-shadow: '1px 1px 1px rgb(0 0 0 / 50%)',
  }
</style>

<a
  class="cursor-pointer relative flex flex-col h-96 content-shadow no-underline rounded-lg bg-white overflow-hidden"
  class:w-80={!autoWidth}
  href={href}
  target="_blank"
>
  <img
    class="w-full h-64 object-cover"
    src={picture}
    alt={description}
  />
  <div class="flex flex-col justify-end">
    <div class="p-2 text-shadow">
      <div class="mb-1 text-2xl font-medium text-pricing">
        {`$${formatNumber(price)}`}
      </div>
      <div class="text-black">
        {description}
      </div>
      <div class="text-black text-opacity-60">
        {`${year} | ${formatNumber(kms)} km | ${location}`}
      </div>
    </div>
  </div>
</a>
