<script>
  import formatNumber from '../utils/formatNumber';

  export let autoWidth = false;
  export let description = '';
  export let href = '';
  export let kms = '';
  export let location = '';
  export let picture = '';
  export let price = '';
  export let year = '';
</script>

<style>
  .text-shadow {
    text-shadow: '1px 1px 1px rgb(0 0 0 / 50%)',
  }
</style>

<a
  class="cursor-pointer relative h-64 block"
  class:w-80={!autoWidth}
  href={href}
  target="_blank"
>
  <img
    class="absolute w-full h-full object-cover"
    src={picture}
    alt={description}
  />
  <div class="absolute w-full h-full flex flex-col justify-end">
    <div class="p-2 text-white bg-black bg-opacity-25 text-shadow">
      <div class="mb-1 text-xl">
        {`$${formatNumber(price)}`}
      </div>
      <div>
        {`${year} | ${formatNumber(kms)} km | ${location}`}
      </div>
      <div>
        {description}
      </div>
    </div>
  </div>
</a>
