<script>
  import useOutsideClick from "../hooks/useOutsideClick";

  export let triggerElement = null;
  export let visible = false;

  let menuElement = null;

  function hideMenu() {
    visible = false;
  }

  useOutsideClick(() => [menuElement, triggerElement], hideMenu);
</script>

{#if visible}
  <div
    bind:this={menuElement}
    class="rounded shadow-md absolute z-10 bg-gray-100 max-h-72 overflow-y-auto min-w-full"
  >
    <slot />
  </div>
{/if}
