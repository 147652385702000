<script>
  export let absolute = false;
  export let flat = false;
  export let fullWidth = false;
  export let relative = false;
  export let root = null;
  export let zIndex = 0;
</script>

<div
  bind:this={root}
  style={zIndex ? `z-index: ${zIndex};` : ''}
  class="bg-white rounded-md shadow-md"
  class:p-4={!flat}
  class:relative={relative}
  class:absolute={absolute}
  class:min-w-full={fullWidth}
>
  <slot />
</div>
