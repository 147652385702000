import { onMount } from "svelte";
import { writable } from "svelte/store";

export default function mediaQuery(query) {
  const state = writable(typeof window === 'undefined' ? false : window.matchMedia(query).matches);

  function updateMedia() {
    const { matches } = window.matchMedia(query);
    state.set(matches);
  }

  onMount(() => {
		window.addEventListener('resize', updateMedia);
		
		return () => {
			window.removeEventListener('resize', updateMedia);
		}
  });

  return state;
}
